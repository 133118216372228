import * as React from "react"
import Container from "@mui/material/Container";
import {Box, Grid} from "@mui/material";
import {StaticImage} from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import {sectionGridSpacing, sectionPadding} from "../theme";

export default () => {
    return (
        <article>
            <Box
                marginBottom={sectionPadding}
            >
            <StaticImage
                src={'../images/sfeer/0.jpg'}
                alt={'water'}
                layout={'fullWidth'}
                aspectRatio={1370/405}
            />
            </Box>
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Grid
                        container
                        spacing={sectionGridSpacing}
                    >
                        <Grid item xs={12} md={5}>
                            <Typography
                                variant={'h2'}
                            >
                                Knus Kerk-Avezaath
                            </Typography>
                            <Typography
                                color={'primary.main'}
                                fontWeight={'medium'}
                            >
                                Kerk-Avezaath is een heerlijk knus dorp in de gemeente Buren, gelegen in de Tielerwaard in Gelderland. Het dorp ligt naast Tiel en nabij Geldermalsen en telt nagenoeg 1.400 inwoners. De omgeving wordt gewaardeerd om zijn gemoedelijkheid, openheid en groene karakter. De rust hoef je niet op te zoeken, dat is er al in overvloed. En zoek je bewust de reuring van de Randstad op, dan ben je bijvoorbeeld al binnen een half uur in Utrecht.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <StaticImage
                                src={'../images/sfeer/bannercontact-1024x479.jpg'}
                                alt={'terras'}
                                layout={'fullWidth'}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
                component={'section'}
            >
                <Container>
                    <Grid
                        container
                        spacing={sectionGridSpacing}
                        direction={'row-reverse'}
                        alignItems={'center'}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            <Typography
                                variant={'h6'}
                            >
                                Praktisch alles tot je beschikking
                            </Typography>
                            <Typography paragraph>
                                Met de gastvrije steden Buren en Tiel op nog geen 10 minuten rijafstand heb je praktisch alles tot je beschikking. Sfeervolle winkelstraatjes, restaurants en cafés, fraaie parken en stadstuinen zijn hier allemaal te vinden.
                            </Typography>
                            <Typography paragraph>
                                Voor een compleet pakket aan dagelijkse boodschappen kun je in Tiel terecht bij winkelcentra als Passewaaij en Westlede. Hier is niet alleen een verscheidenheid aan supermarkten aanwezig, maar ook winkels als een dierenspeciaalzaak, bloemist, drogisterij en een boekhandel. Sporten kan je in Kerk-Avezaath in overvloed. Denk aan voetballen bij de Teisterbanders, tennis bij LTC Kellendonck of gymnastiek en dansen bij AGA.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <StaticImage
                                src={'../images/sfeer/buren-gelderland-eiland-van-maurik.png'}
                                alt={'eiland van maurik'}
                                layout={'fullWidth'}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
                component={'section'}
            >
                <Container>
                    <Grid
                        container
                        spacing={sectionGridSpacing}
                    >
                        <Grid item xs={12} md={5}>
                            <Typography
                                variant={'h6'}
                            >
                                Direct onderweg
                            </Typography>
                            <Typography paragraph>
                                Aan de rand van het dorp bevindt zich het busstation dat zorgt voor een toegankelijke verbinding met de buursteden. Zo sta je binnen no time op het treinstation van Tiel. Daarnaast rijd je in enkele minuten naar de A15 die bij knooppunt Deil de A2 kruist, waardoor je in ongeveer 30 minuten Utrecht bereikt.
                            </Typography>
                            <Typography
                                variant={'h6'}
                            >
                                Genieten in de natuur
                            </Typography>
                            <Typography paragraph>
                                Vanuit Kerk-Avezaath loopt het klompenpad Teisterbantpad, richting Zoelen. Dit wandelpad geeft elke natuurliefhebber de mogelijkheid tot het verkennen van het prachtige landschap rondom het dorp. Boomgaarden, stroomruggen, komkleigebieden, monumentale herenboerderijen en het mooie oude landgoed Soelen zijn hier allemaal te ontdekken. Een zaterdagmiddag of zondagochtend welbesteed.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <StaticImage
                                src={'../images/sfeer/Klompenpad-Teisterbantpad-9.jpg'}
                                alt={'Klompenpad Teisterbantpad'}
                                layout={'fullWidth'}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </article>
    )
}
